import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Theme, ThemeColors } from "../../platform/enums/theme";
import { openLink } from "../../platform/helpers/util";
import ButtonPR from "../../components/shared/Button/Buttons";
import userImage from "../../assets/images/user.png";
import * as Actions from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { selectTheme } from "../../redux/theme/theme.selector";
import { selectUser } from "../../redux/user/user.selector";
import { LoginInterface } from "../../interfaces";
import { baseURL } from "../../platform/api/api";
import "./LoginPage.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginUserSchema } from "../../validations/Loginuser.validation";
import localStorage from "redux-persist/es/storage";
import {
  RoleEnum,
  RoleImages,
  SaleAccessLevels,
} from "../../platform/enums/role";
import {
  BuildRoutes,
  LoginRoutes,
  ManagementRoutes,
  SaleRoutes,
  TenantRoutes,
} from "../../platform/enums/Routes";

const BGCircle = styled.div<any>`
  position: fixed;
  width: 1564px;
  height: 1264px;
  // z-index: -11;
  background: ${(props) => props.bg};
  border-radius: 1023.84px;
  left: calc(50% - 1964px / 2 - 327px);
  top: calc(50% - 1264px / 2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  /* transition: all ease-in-out 0.1s; */

  &::before {
    content: "";
    width: 300px;
    height: 100%;
    position: absolute;
    /* top: 407px;
        left: 38%; */
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(${(props) => props.roleImage});
    top: 480px;
    left: 43%;
    /* transition: all ease-in-out 0.1s; */
  }
  &::after {
    content: "${(props) => props.imageTitle}";
    position: relative;
    display: block;
    top: 884px;
    left: 0%;
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 105%;
    /* identical to box height, or 42px */

    text-align: center;
    letter-spacing: -1px;

    /* White */

    color: #ffffff;
  }
`;

const UserRole = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 9.65094px;
  line-height: 13px;
  color: #ebebeb;
`;

const BadgeWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const Text = styled.span<any>`
  color: ${(props) => props.color};
  margin: 0 ${(props) => (props.right ? "19px" : false)} 0
    ${(props) => (props.left ? "19px" : false)};
`;

const ErrorText = styled.div`
  color: #ff4646;
`;

const SuccessText = styled.div<any>`
  color: ${(props) => props.color};
`;

const Loader = styled.div<any>`
  border-top-color: ${(props) => props.color};
  margin: 0 auto;
`;

const SuccessWrapper = styled.div`
  position: relative;
  top: -30%;
`;

const Button = styled.button<any>`
  background: ${(props) => props.bg};
  border-radius: 12px;
  padding: 19px 33%;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  border: none;
  text-align: center;
  outline: none;
  color: #fff;
  margin-bottom: 46px;
`;

const LoginBadgeCircle = styled.div`
  background: #ffffff;
  vertical-align: middle;
  box-shadow: 0px 8px 23px rgba(77, 76, 76, 0.06);
  width: 59px;
  height: 59px;
  border-radius: 50%;
  padding: 0px 0px;
  /* margin-left: 20px; */
  margin-right: 20px;
  display: inline-block;
`;

const LoginBadgeText = styled.span`
  font-size: 22px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.9);
`;

const LoginBadgeIcon = styled.img`
  width: 59px;
`;

const Logo = styled.img`
  margin-left: 66px;
`;

const LogoWrapper = styled.div`
  position: fixed;
  top: 75px;
  left: 81px;
`;

const Container = styled.div`
  display: block;
  width: 100%;
`;

const LoginBadge = styled.div<any>`
  padding: 18px 13px;
  min-width: 239px;
  width: 239px;
  height: 95px;
  border-radius: 81px;
  background: linear-gradient(
    180deg,
    ${(props) => props.bg} 0%,
    ${(props) => props.bgsec} 100%
  );
  padding: 18px 22px;
  margin-bottom: 42px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 95px;
  position: relative;
  top: -43px;
  left: 0;
  cursor: pointer;
  transition: all 0.3s;
  @media only screen and (max-width: 320px) {
    &:hover {
      left: 0px !important;
    }
  }
  &:hover {
    transition: all 0.3s;
  }
`;
const ForgetPass = styled.div`
  margin-bottom: 46px;
  display: flex;
  justify-content: space-evenly;
`;
const LandingPages = styled.p`
  color: #41bcfe;
  cursor: pointer;
  text-decoration: underline;
`;
const accessSetter = (roles: any) => {
  if (roles.build?.allow) {
    return { access: roles.build?.access ?? "super_admin", role: "Build" };
  }
  if (roles.management?.allow) {
    return {
      access: roles.management?.access ?? "super_admin",
      role: "Management",
    };
  }
  if (roles.sales?.allow) {
    return { access: roles.sales?.access ?? "super_admin", role: "Sales" };
  }
  if (roles.tenant?.allow) {
    return { access: roles.tenant?.access ?? "super_admin", role: "Tenant" };
  }
};
const userObjSetter = (user: any) => {
  const roleAccess: any = accessSetter(user.roles);
  return { ...user, ...roleAccess };
};

const LoginPage: React.FC = (props: any) => {
  const theme = useSelector(selectTheme);
  const user = useSelector(selectUser);
  const [formError, setFormError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [countDown, setCountDown]: any = useState(5);
  const dispatch = useDispatch();

  const routeSetter = (user: any) => {
    switch (user.role) {
      case RoleEnum.Sales:
        if (
          user.access === SaleAccessLevels.acquisition_manager ||
          user.access === SaleAccessLevels.acquisition_assistant ||
          user.access === SaleAccessLevels.super_admin
        ) {
          history.push(SaleRoutes.stocklist);
          break;
        } else {
          history.push(SaleRoutes.leads);
          break;
        }
      case RoleEnum.Tenant:
        history.push(TenantRoutes.home);
        break;
      case RoleEnum.Build:
        history.push(BuildRoutes.home);
        break;
      case RoleEnum.Management:
        history.push(ManagementRoutes.home);
        break;
    }
  };

  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginUserSchema) });
  const signin: SubmitHandler<any> = async (data: LoginInterface) => {
    setFormError(false);
    setLoading(true);
    const endPoint = axios.create({
      baseURL: baseURL,
    });
    endPoint
      .post("user/sign-in", data)
      .then((res) => {
        // const resUser = res.data.user;
        const roles = res.data.roles;
        const user = userObjSetter({ ...res.data.user, roles });
        localStorage.setItem("authToken", user.token);
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("constants", JSON.stringify(res.data.constants));
        dispatch(Actions.setUser(user));
        const userTheme =
          Theme[
            Object.keys(Theme).find(
              (i) => Theme[i as keyof ThemeColors]["role"] === user.role
            ) as keyof ThemeColors
          ];
        dispatch(Actions.setTheme(userTheme));
        setCountDown(5);

        setIsLoggedin(true);
        setFormError(false);
        routeSetter(user);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.message);
        setIsLoggedin(false);
        setFormError(true);
        setLoading(false);
        // setUser({});
        dispatch(Actions.setUser({ "": "" }));
      });
  };

  const roleImage = (role: string) => {
    let image = "";
    switch (role) {
      case RoleEnum.Sales:
        image = RoleImages.Sales;
        break;
      case RoleEnum.Build:
        image = RoleImages.Build;
        break;
      case RoleEnum.Tenant:
        image = RoleImages.Tenant;
        break;
      case "Manager":
        image = RoleImages.Management;
        break;
      default:
        break;
    }
    return image;
  };

  return (
    <>
      <div className="login-page-wrapper">
        <Container>
          <LogoWrapper className="left-top-box">
            <span className="button-left">
              <ButtonPR
                bg="#fff"
                onClick={() => props.back(false)}
                type={"circle"}
                icon={
                  <svg
                    width="8px"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-left"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill={theme.hex}
                      d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                    />
                  </svg>
                }
              />
              <Text color="#fff" right={true}>
                Go Back
              </Text>
            </span>
            <Logo src="/static/icons/logo-new.svg" className="loginlogo" />
          </LogoWrapper>

          <div className="top-actions">
            <span className="button-right">
              <ButtonPR
                onClick={() =>
                  openLink(
                    "https://www.notion.so/SDA-SHA-USAGE-DOCUMENTATION-272b3577317744c196df5ad6d3d41049?p=1d21506cda8b469f936004a800ff840d"
                  )
                }
                type={"circle"}
                className="rightques"
                size="small"
                icon={
                  <svg
                    width="6"
                    height="12"
                    viewBox="0 0 6 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.07247 11.0312C3.57768 11.0312 3.98723 10.6216 3.98723 10.1164C3.98723 9.61121 3.57768 9.20166 3.07247 9.20166C2.56727 9.20166 2.15771 9.61121 2.15771 10.1164C2.15771 10.6216 2.56727 11.0312 3.07247 11.0312Z"
                      fill="#fff"
                    />
                    <path
                      d="M3.07273 0.96875C1.45866 0.96875 0.145508 2.2819 0.145508 3.89597C0.145508 4.30015 0.473137 4.62777 0.877313 4.62777C1.28149 4.62777 1.60912 4.30015 1.60912 3.89597C1.60912 3.08894 2.26569 2.43236 3.07273 2.43236C3.87976 2.43236 4.53634 3.08894 4.53634 3.89597C4.53634 4.703 3.87976 5.35958 3.07273 5.35958C2.66855 5.35958 2.34092 5.68721 2.34092 6.09138V7.9209C2.34092 8.32507 2.66855 8.6527 3.07273 8.6527C3.4769 8.6527 3.80453 8.32507 3.80453 7.9209V6.73058C5.06565 6.40482 5.99995 5.25746 5.99995 3.89597C5.99995 2.2819 4.6868 0.96875 3.07273 0.96875Z"
                      fill="white"
                    />
                  </svg>
                }
              />
            </span>
          </div>
          <BGCircle
            bg={theme.hex}
            imageTitle={props.role}
            roleImage={roleImage(props.role)}
          />
          <div className="row round-wrapper-login">
            <div className="col-md-12">
              <LoginBadge bg={theme.hex} bgsec={theme.hex}>
                <LoginBadgeCircle>
                  <LoginBadgeIcon width="107px" src={userImage} />
                </LoginBadgeCircle>
                <BadgeWrapper className="roll">
                  <LoginBadgeText>
                    {user != null && user["name"]
                      ? user.name.slice(0, 12)
                      : props.role}
                  </LoginBadgeText>
                  <UserRole>Department</UserRole>
                </BadgeWrapper>
              </LoginBadge>
              {isLoggedin && !loading && (
                <SuccessWrapper className="mb-4">
                  <SuccessText color={theme.hex}>
                    Success! Welcome <strong>{user ? user.name : ""}</strong> -{" "}
                    {countDown}{" "}
                  </SuccessText>
                </SuccessWrapper>
              )}
            </div>
            {!isLoggedin && (
              <div className="col-md-12">
                <div className="form-group">
                  <form onSubmit={handleSubmit(signin)}>
                    <div className="input-wrapper">
                      <label htmlFor="email">Email Address</label>
                      <input
                        type="email"
                        {...register("email")}
                        className="mb-0"
                        placeholder="example@domain.com"
                      />
                      <p className="mb-4 text-start text-danger error">
                        <small>{errors?.email?.message}</small>
                      </p>
                    </div>
                    <div className="input-wrapper">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        {...register("password")}
                        className="mb-0"
                        placeholder="Password"
                      />
                      <p className="mb-4 text-start text-danger error">
                        <small>{errors?.password?.message}</small>
                      </p>
                    </div>
                    <Button type="submit" bg={theme.hex} className="">
                      Continue
                    </Button>
                    <ForgetPass>
                      <div>
                        <LandingPages
                          onClick={() =>
                            history.push(LoginRoutes.resetpassword)
                          }
                        >
                          Forgot Password
                        </LandingPages>
                      </div>
                      <p>OR</p>
                      <div>
                        <LandingPages
                          onClick={() => history.push(LoginRoutes.signup)}
                        >
                          Create a new account
                        </LandingPages>
                      </div>
                    </ForgetPass>
                  </form>
                  {formError && (
                    <div className="mb-4">
                      <ErrorText>{errorMessage}</ErrorText>
                    </div>
                  )}
                  {loading && (
                    <div className="mb-4 text-center">
                      <Loader color={theme.hex} className="min-loader"></Loader>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default LoginPage;
